import { Tag } from "antd";
import moment from "moment";
import { AiOutlineTeam } from "react-icons/ai";
import { CgFileDocument, CgTranscript } from "react-icons/cg";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { ImProfile } from "react-icons/im";
import { PiPlusMinusBold } from "react-icons/pi";
import { VscLaw } from "react-icons/vsc";
import { GrUserExpert } from "react-icons/gr";
import { IoNewspaperOutline } from "react-icons/io5";
import { BiBarChartSquare } from "react-icons/bi";
import { TfiAnnouncement } from "react-icons/tfi";
import { CiStar } from "react-icons/ci";
import company_icon3 from "../assests/images/company_notfound.png";

export const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

export const convertToTitleCase = (inputString = "") => {
  return inputString.toLowerCase().replace(/\b\w/g, function (match) {
    return match.toUpperCase();
  });
};

export const getModifiedDescription = (description) => {
  description = description?.replaceAll("-", "");
  description = description?.replaceAll("\n\n", "\n");
  description = description?.replaceAll("Overview:\n-", "\n");
  description = description?.replaceAll("\n-", "\n");
  description = description?.split("\n");
  return description;
};

export const formattedDate = (date) => {
  return moment(date).format("DD MMM YY");
};
export const formattedPrice = (price) => {
  const numberWithCommas = (number) => {
    return number?.toLocaleString("en-US");
  };

  const formattedPrice = numberWithCommas(price);
  return formattedPrice;
};

export const isFloat = (value) => {
  return Number(value) === value && value % 1 !== 0;
};
export const priceInCr = (value) => {
  let formatedValue = Number(String(value)?.replace(/,/g, ""));
  let yearValue = "";
  if (!isNaN(formatedValue)) {
    var formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
    });
    if (!isFloat(formatedValue)) {
      let convertToCr = formatedValue / 10000000;
      yearValue = formatter.format(convertToCr);
    } else {
      yearValue = formatedValue?.toFixed(2);
    }
  } else {
    yearValue = value;
  }
  return yearValue + " Cr.";
};

export const contentTags = (data) => (
  <>
    <div className="trans_popup" style={{ width: "500px" }}>
      {data?.meetingsData?.[0]?.company_id?.slice(4)?.map((brand) => (
        <>
          {brand && (
            <Tag className="me-1" size="large" key={brand?.title?.trim()}>
              {brand?.name?.trim()}
            </Tag>
          )}
        </>
      ))}
    </div>
  </>
);

export function truncate(str, n) {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
}
export function truncatelast(str) {
  return str?.length > 50 ? str?.substr(0, 50) + "..." : str;
}

export function isUpperCase(word) {
  return word === word.toUpperCase();
}

export const tabInfo = [
  {
    title: "About",
    logo: <ImProfile size={14} />,
  },
  {
    title: "Transcripts",
    logo: <CgTranscript size={14} />,
  },
  {
    title: "Experts",
    logo: <GrUserExpert size={14} />,
  },
  {
    title: "News",
    logo: <IoNewspaperOutline size={14} />,
  },
  {
    title: "Directors",
    logo: <AiOutlineTeam size={14} />,
  },
  {
    title: "Financials",
    logo: <BiBarChartSquare size={14} />,
  },
  {
    title: "Charges",
    logo: <HiOutlineCurrencyRupee size={14} />,
  },
  {
    title: "Rating",
    logo: <CgFileDocument size={14} />,
  },
  {
    title: "Auditors",
    logo: <PiPlusMinusBold size={14} />,
  },
  {
    title: "Associates",
    logo: (
      <svg
        _ngcontent-ng-c2314036997=""
        width="14"
        height="14 "
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          _ngcontent-ng-c2314036997=""
          d="M10.5 13.6H10.36C9.18384 13.6 8.59579 13.6 8.14657 13.3711C7.75143 13.1698 7.43017 12.8485 7.22884 12.4534C6.99995 12.0042 6.99995 11.4161 6.99995 10.24V5.76C6.99995 4.58389 6.99995 3.99583 7.22884 3.54662C7.43017 3.15148 7.75143 2.83022 8.14657 2.62889C8.59579 2.4 9.18384 2.4 10.36 2.4H10.5M10.5 13.6C10.5 14.3732 11.1268 15 11.9 15C12.6731 15 13.3 14.3732 13.3 13.6C13.3 12.8268 12.6731 12.2 11.9 12.2C11.1268 12.2 10.5 12.8268 10.5 13.6ZM10.5 2.4C10.5 3.1732 11.1268 3.8 11.9 3.8C12.6731 3.8 13.3 3.1732 13.3 2.4C13.3 1.6268 12.6731 1 11.9 1C11.1268 1 10.5 1.6268 10.5 2.4ZM3.49995 8L10.5 8M3.49995 8C3.49995 8.7732 2.87315 9.4 2.09995 9.4C1.32675 9.4 0.699951 8.7732 0.699951 8C0.699951 7.2268 1.32675 6.6 2.09995 6.6C2.87315 6.6 3.49995 7.2268 3.49995 8ZM10.5 8C10.5 8.7732 11.1268 9.4 11.9 9.4C12.6731 9.4 13.3 8.7732 13.3 8C13.3 7.2268 12.6731 6.6 11.9 6.6C11.1268 6.6 10.5 7.2268 10.5 8Z"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    ),
  },
  {
    title: "Litigations/Defaults",
    logo: <VscLaw size={14} />,
  },
  {
    title: "Compliance",
    logo: <CgFileDocument size={14} />,
  },
  {
    title: "Corporate Announcements",
    logo: <TfiAnnouncement size={14} />,
  },
  {
    title: "Following",
    logo: <CiStar size={18} />,
  },
];

export const convertToHtmlTranscript = (recordingData) => {
  function doubleDigit(digit) {
    if (digit < 10) {
      return "0" + digit;
    } else {
      return digit;
    }
  }
  function convertMS(time) {
    var hour = "";
    var min = Math.floor((time / (1000 * 60)) % 60),
      hours = Math.floor((time / (1000 * 60 * 60)) % 24),
      sec = Math.floor((time / 1000) % 60);
    if (hours) {
      hour = `${doubleDigit(hours)}:`;
    }
    return `${hour}${doubleDigit(min)}:${doubleDigit(sec)}`;
  }
  const convertDate = (date) => {
    if (date !== null && date !== "" && date !== undefined) {
      const localDate = new Date(date);
      const localDateString = localDate.toLocaleDateString(undefined, {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      const localTimeString = localDate.toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      let p = `${localDateString}`;
      return p;
    } else {
      return "-";
    }
  };
  function replaceTxt(txt, para) {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }
  return `<div id="scriptBody" style="width: 880px">
  <h6 style="text-align:center;color:#5746ec;font-weight:600">${recordingData?.companyName
    } - ${replaceTxt(recordingData?.topic, [
      "nextyn",
      "micro",
      "consulting",
      "-consulting",
      "call - ",
      "call",
      "-",
    ])?.toUpperCase()} - ${convertDate(recordingData?.recording_start)}</h6>
  <div style="height:1px; background: #000"></div>
    ${recordingData?.assembly_response?.utterances?.map(
      (value) => `<div style='position:relative;margin-top:20px;'>
                      <div>
                          <h6 style='margin-bottom: 1;display:flex;align-items:center;font-weight:600;color:#000;font-size: 12px'>
                              <strong>Speaker ${value.speaker}</strong>
                              <span style='color:#8294a5;font-size:12px;font-weight:400;display:inline-block;margin-left: 14px'>
                                  ${convertMS(value?.end)}
                              </span>
                          </h6>
                          <p style='font-size:12px;color:#05294b;cursor:pointer';>${value.text
        }</p>
                      </div>
                  </div>`
    )}
    </div>`;
};

export const toQueryString = (paramsArray) => {
  let params_obj = {};
  Object.entries(paramsArray)
    ?.filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        value?.length !== 0
    )
    .forEach(([key1, value]) => {
      if (Array.isArray(value)) {
        params_obj = {
          ...params_obj,
          [key1]: JSON.stringify(value),
        };
      } else {
        if (
          [
            "page",
            "limit",
            "key",
            "expert_type",
            "search",
            "searchkey",
          ].includes(key1)
        ) {
          params_obj = {
            ...params_obj,
            [key1]: value,
          };
        } else {
          params_obj = {
            ...params_obj,
            [key1]: JSON.stringify([value]),
          };
        }
      }
    });
  return params_obj;
};

export const checkForValidURL = (url) => {
  let splitUrl = url?.split(".");
  if (
    ["https://media", "https://media-exp1"]?.includes(splitUrl?.[0]) ||
    !url
  ) {
    return company_icon3;
  } else {
    return url;
  }
};

export const getQueryParams = (query) => {
  if (query?.startsWith("?")) {
    query = query?.slice(1);
  }

  const result = {};
  let pairs = [];
  let buffer = "";
  let inBrackets = false;

  for (let i = 0; i < query?.length; i++) {
    const char = query[i];

    if (char === "[") inBrackets = true;
    if (char === "]") inBrackets = false;

    if (char === "&" && !inBrackets) {
      pairs?.push(buffer);
      buffer = "";
    } else {
      buffer += char;
    }
  }
  pairs?.push(buffer); // push the last part after the loop
  pairs.forEach((pair) => {
    const [key, value] = pair?.split("=");
    const decodedValue = decodeURIComponent(value?.replace(/\+/g, " "));
    // Check if the decoded value is a JSON array
    if (decodedValue?.startsWith("[") && decodedValue?.endsWith("]")) {
      try {
        result[key] = JSON.parse(decodedValue);
      } catch (e) {
        result[key] = decodedValue;
      }
    } else {
      result[key] = decodedValue;
    }
  });
  return result;
};
