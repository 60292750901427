import { Select, Input, Spin } from "antd";
import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import API_PATH from "../../../Constants/api-path";
import MobileMenu from "../../../MobileMenu";
import UserMangementTable from "./UserMangementTable";
import { Modal } from "react-bootstrap";
import { MdOutlineClose } from "react-icons/md";
import { Form, Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { countryOptions } from "../../../Utils/Util";



const UserMangement = () => {
  document.title = "Nextyn IQ -  User Management";
  let UserData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const [spinner, setSpinner] = useState(false);
  const headers = {
    "x-access-token": userData.access_token,
  };
  const [phone, setPhone] = useState("");
  const [showCreateModal, setCreateModal] = useState(false);
  const [form] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [data, setUserData] = useState([]);
  const [addUser, setAddUser] = useState({
    firstNmae: "",
    email: "",
    contact_code: "",
    contact_number: "",
    country: "",
    password: "",
    role_id: null,
    registrationType: "cloud",
    contact_number_full: "",
  });
  const [message, setMessage] = useState("");

  useEffect(() => {
    form.setFieldsValue(addUser);
  }, [form, addUser]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    setLoading(true);
    axios
      .get(`${API_PATH.USER_MANAGEMENT}/${UserData.user.company_id._id}`, {
        method: "GET",
        headers: headers,
      })
      .then((res) => {
        console.log(res, "ress");
        setUserData([...res.data.data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleSubmit = async () => {
    let obj = { ...addUser }
    const { firstNmae, email, role_id } = obj;
    let fullEmail = `${addUser?.email?.toLowerCase()?.split("@")?.[0]}@${userData?.user?.email?.split("@")[1]}`

    if (firstNmae !== "" && email !== "" && phone !== "" && role_id !== "") {
      const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regEx.test(fullEmail)) {
        return setMessage("Email is invalid");
      } else {
        setMessage("");
      }

      setSpinner(true);


      obj.email = fullEmail;
      await axios
        .post(`${API_PATH.USER_MANAGEMENT_POST}`, JSON.stringify(obj), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res: ", res);
          getUsers();
          // console.log(res);
          setSpinner(false);

          //navigate("/privacy")
          if (res.status === 200) {
            // console.log(res.status);

            toast.success("UserData Added Successful", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setCreateModal(false);
            // window.location.reload();
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setCreateModal(false);
          setSpinner(false);
        });
    } else {
      return toast.warning("All fields are mandatory!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const getUserList = async () => {
    try {
      const res = await axios.get(
        `${API_PATH.ROLE_MANAGEMENT}?company_id=${userData?.user?.company_id?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );
      const response = res.data;
      // console.log(response);
      setRoleList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll > 0) {
      $("#header").addClass("active");
    } else {
      $("#header").removeClass("active");
    }
  });

  const deleteUser = async (data) => {
    // console.log(data);
    axios
      .delete(`${API_PATH.USER_MANAGEMENT_DELETE}/${data}`, {
        method: "DELETE",
        headers: headers,
      })
      .then((res) => {
        getUsers();
        if (res) {
          if (res?.data?.success === true) {
            // console.log(res.status);
            toast.success("UserData Deleted Successful", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              // window.location.reload();
            }, 3000);
          } else {
            if (
              res?.data?.success === false &&
              res?.data?.message === "Your account has been saved"
            ) {
              toast.success(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              toast.error(res?.data?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      });
  };

  const validateEmailDomain = (_, value) => {
    // Check if value is a valid email address
    let isValidEmail = value?.includes("@")

    if (value && isValidEmail) {
      return Promise.reject(
        `please provide the username only.`
      );
    }
    // Email is valid
    return Promise.resolve();
  };

  return (
    <>
      <MobileMenu />

      <div
        className="bg-light content-wrapper"
        id="main-screen"
        style={{ overflow: "scroll" }}
      >
        <div className="body-expart-div projects-pages expert-cell-page">
          <div className="d-flex top-ps-seection justify-content-between align-items-center">
            <h5
              style={{ fontWeight: 600 }}
              className="d-flex align-items-center"
            >
              Users
            </h5>
            {permission?.userManagement?.add && (
              <button
                className="btn viewButton"
                onClick={() => {
                  setAddUser({
                    firstNmae: "",
                    email: "",
                    contact_code: "",
                    contact_number: "",
                    country: "",
                    password: "",
                    role_id: null,
                    registrationType: "cloud",
                  });
                  setPhone("");
                  setCreateModal(true);
                }}
              >
                {" "}
                <BsPlusLg /> Add New{" "}
              </button>
            )}
          </div>

          <div className="managment-table mt-2">
            <UserMangementTable
              data={data}
              deleteUser={deleteUser}
              load={loading}
              getUsers={getUsers}
            />
          </div>
        </div>
      </div>

      <Modal
        show={showCreateModal}
        onHide={() => setCreateModal(false)}
        backdrop="static"
        keyboard={false}
        centered
        size={"lg"}
        scrollable={true}
        dialogClassName={"bootStrapModal"}
        contentClassName={"bootStrapModalContent"}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={addUser}

          onFinish={() => {
            handleSubmit();
            form.resetFields();
          }}
        >
          <Modal.Header>
            <MdOutlineClose
              className="position-absolute"
              style={{ right: "15px", top: "15px", fontSize: "25px" }}
              onClick={() => setCreateModal(false)}
            />
            <div className="text-left">
              <h4 className="modalHeader">Add Users</h4>
            </div>
          </Modal.Header>
          <Modal.Body className="position-relative ">
            <div>
              <div className="projects-modal-divs UserModal">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Form.Item
                        label="Name"
                        name={"firstNmae"}
                        rules={[
                          { required: true, message: "name is required!" },
                        ]}
                      >
                        <Input
                          type="text"
                          name="firstNmae"
                          maxLength={40}
                          size="large"
                          width={"95%"}
                          placeholder="Name"
                          // value={addUser.firstNmae}
                          // onChange={handleInputs}
                          onChange={(e) => {
                            let obj = {
                              ...addUser,
                              firstNmae: e.target.value,
                            };
                            setAddUser(obj);
                            form.setFieldValue("firstNmae", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group addRightInput">
                      <Form.Item
                        label="Email"
                        name="email"
                        maxLength={50}
                        rules={[
                          {
                            required: true,
                            message: "email is required!",
                          },
                          {
                            validator: validateEmailDomain, // Custom validator function
                          },
                        ]}
                      >
                        <Input
                          // type="email"
                          name="email"
                          size="large"
                          maxLength={50}
                          width={"95%"}
                          // value={addUser.email}
                          placeholder="Enter email address"
                          // onChange={handleInputs}
                          addonAfter={
                            "@" + userData?.user?.email?.split("@")[1]
                          }
                          onChange={(e) => {
                            let obj = {
                              ...addUser,
                              email: e.target.value,
                            };
                            setAddUser(obj);
                            form.setFieldValue("email", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                    {message === "" ? (
                      <></>
                    ) : (
                      <p className="text-danger">{message}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      {/* <label> Contact Number * </label> */}
                      <Form.Item
                        label="Contact Number"
                        name="contact_number_full"
                        rules={[
                          {
                            required: true,
                            message: "contact number is required!",
                          },
                        ]}
                      >
                        <PhoneInput
                          country={"us"}
                          name="contact_number_full"
                          enableSearch={true}
                          // value={phone}
                          onChange={(value, country) => {
                            setPhone(value);
                            const selectedCountry = countryOptions?.find(
                              (option) =>
                                option?.code === `+${country?.dialCode}`
                            );
                            if (selectedCountry) {
                              setAddUser((prevStep3) => ({
                                ...prevStep3,
                                contact_code: country?.dialCode,
                                contact_number: value?.replace(
                                  country?.dialCode,
                                  ""
                                ),
                                country: selectedCountry?.name,
                                contact_number_full: value,
                              }));
                            }
                            form.setFieldValue("contact_number_full", value);
                          }}
                          // onChange={(value, country) => {
                          //   handlePhoneChange(value, country);
                          // }}
                          inputStyle={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <Form.Item
                        label="Role"
                        name="role_id"
                        rules={[
                          { required: true, message: "Role is required!" },
                        ]}
                      >
                        <Select
                          className="w-100"
                          size="large"
                          placeholder="select role"
                          // aria-label="Default select example"
                          value={addUser.role_id}
                          onChange={(value) =>
                            setAddUser({ ...addUser, role_id: value })
                          }
                          name="role_id"
                          id="role_name"
                          options={roleList?.map((item, index) => ({
                            label: item?.name,
                            value: item?._id,
                            key: index,
                          }))}
                        />
                      </Form.Item>
                      {/* <select
                        className="form-control"
                        aria-label="Default select example"
                        onChange={handleInputs}
                        name="role_id"
                      >
                        {roleList?.map((option, index) => (
                          <option value={option._id} key={index}>
                            {option.name}
                          </option>
                        ))}
                      </select> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end align-items-center">
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                key="back"
                type="button"
                onClick={() => {
                  console.log("cancelling");
                  setCreateModal(false)
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="submit"
                htmlType="submit"
                style={{
                  backgroundColor: "rgb(76, 187, 23)",
                  borderColor: "rgb(76, 187, 23)",
                  color: "#fff",
                }}
                className=" ms-2"
              >
                Submit {spinner && <Spin size="small" indicator={<LoadingOutlined />} />}
              </Button>
            </Form.Item>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* // add user modal */}
    </>
  );
};

export default UserMangement;
