import React, { useState, useEffect } from "react";
import "../../../App.css";
import "../../../assests/css/custom.css";
import API_PATH from "../../../Constants/api-path";
import axios from "axios";
import { List } from "antd";
import no_result from "../../../assests/images/oops.svg";
import { Col, Row } from "react-bootstrap";
import ExpertDetailsDrawer from "./ExpertDetails";

function CompletedTable({ project_id }) {
  //console.log(project_id)
  const [CompletedExperts, setCompletedExperts] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [expertID, setExpertID] = useState("");
  const [submitPop, setSubmitPop] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  let serialNumber = 0;

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };
  //console.log(UserData.user.email)

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      // Check if the date is in the format "08, 2023 - 09, 2023"
      if (/^\d{2}, \d{4} - \d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 - "
      if (/^\d{2}, \d{4} - $/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "08, 2023 "
      if (/^\d{2}, \d{4}$/.test(date)) {
        const [startMonthNum, startYear] = date.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "2018-04"
      if (/^\d{4}-\d{2}$/.test(date)) {
        const [year, monthNum] = date?.split("-");
        const month = getMonthName(monthNum);
        return `${month}, ${year}`;
      }

      // Check if the date is in the format "10, 2014 - 01, 2020"
      if (/^\d{2}, \d{4}\s*-\s*\d{2}, \d{4}$/.test(date)) {
        const [startDate, endDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // Check if the date is in the format "02, 2018   -    "
      if (/^\d{2}, \d{4}\s*-\s*$/.test(date)) {
        const [startDate] = date?.split(" - ");
        const [startMonthNum, startYear] = startDate.trim().split(", ");
        const startMonth = getMonthName(startMonthNum);
        return `${startMonth}, ${startYear}`;
      }

      // For other formats, convert the date to the desired format
      var d = new Date(date);
      var day = d.getDate();
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var month = monthNames[d.getMonth()];
      var year = d.getFullYear();
      let formattedDate = `${day} ${month}, ${year}`;
      return formattedDate;
    } else {
      return "---------";
    }
  };

  const getMonthName = (monthNum) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const index = parseInt(monthNum, 10) - 1;
    return monthNames[index];
  };

  useEffect(() => {
    getExperts();
    fetchData();
  }, []);

  const getExperts = () => {
    axios
      .get(`${API_PATH.Client_Project_Expert_List}/${project_id}`, { headers })
      .then((res) => {
        console.log(res?.data?.data);
        setLoading(true);
        setCompletedExperts(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("CompletedExperts", CompletedExperts);

  const fetchData = (_id) => {
    axios
      .get(
        `${API_PATH.PROJECT_EXPERT_GET}/${_id}`,
        // { email: UserData.user.email },
        { headers }
      )
      .then((res) => {
        setLoading(true);
        console.log(res, "resExpertIdData");
        setSubmitPop(res?.data?.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getExpertDetails = (_id) => {
    console.log(_id, "expertId");
    setExpertID(_id, "expertId");
    fetchData(_id);
    // console.log("obj found update func res", res);
    // setSubmitPop(res);
  };

  console.log("submitPopppp", submitPop);

  return (
    <>
      <>
        {console.log(CompletedExperts, "submittedExperts-->")}
        {CompletedExperts.filter(
          (elem) => elem?.admin_invite_experts[0]?.status === 5
        ).length > 0 ? (
          <>
            <div>
              <List
                className="mt-3"
                size="small"
                header={
                  <Row>
                    {/* <Col lg={2}>
                      <strong className="fw-bolder">STATUS</strong>
                    </Col> */}
                    <Col lg={4}>
                      <strong className="fw-bolder">MOST RELEVANT TITLE</strong>
                    </Col>
                    <Col lg={3}>
                      <strong className="fw-bolder">COMPANY</strong>
                    </Col>
                    <Col lg={2}>
                      <strong className="fw-bolder">DATE</strong>
                    </Col>
                    <Col lg={1}>
                      <strong className="fw-bolder">VIEW</strong>
                    </Col>
                  </Row>
                }
                bordered
                dataSource={CompletedExperts.filter(
                  (expert) => expert?.admin_invite_experts[0]?.status === 5
                )}
                renderItem={(item) => (
                  <List.Item>
                    <Row className="w-100 mt-1">
                      {/* <Col lg={2}>
                        <span className="requested-text">Submitted</span>
                      </Col> */}
                      <Col lg={4}>
                        <p className="p-0 m-0" style={{ fontSize: "14px" }}>
                          {item?.admin_expert_experience[0]
                            ?.current_designation ||
                            item?.admin_expert_experience[0]
                              ?.previous_designation}
                        </p>
                      </Col>
                      <Col lg={3}>
                        <p className="p-0 m-0" style={{ fontSize: "14px" }}>
                          {item?.admin_expert_experience[0]?.current_compny ||
                            item?.admin_expert_experience[0]?.previous_compny}
                        </p>
                      </Col>
                      <Col lg={2}>
                        <p className="p-0 m-0" style={{ fontSize: "14px" }}>
                          {convertDate(item.created_at)}
                        </p>
                      </Col>
                      <Col lg={1}>
                        <a
                          type="button"
                          style={{ fontWeight: "600", color: "#5746EC" }}
                          // Consider using a unique key for List.Item
                          // key={item._id}
                          onClick={() => {
                            toggleDrawer();
                            getExpertDetails(item._id);
                            // fetchDataProfile(item?.expert_id);
                          }}
                        >
                          View
                        </a>
                      </Col>
                    </Row>
                  </List.Item>
                )}
                style={{ background: "#ffff" }}
              />
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img src={no_result} style={{ height: "350px" }}></img>
                    </div>
                    <div className="col-md-6">
                      <h3
                        style={{
                          fontSize: "18px",
                          textAlign: "left",
                          fontWeight: "300",
                          lineHeight: "26px",
                        }}
                      >
                        Looks like you're yet to successfully complete a
                        consultation for this project. Fingers crossed, this
                        page will be full of successful calls, shortly.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        )}

        <ExpertDetailsDrawer
          toggleDrawer={toggleDrawer}
          isDrawerVisible={isDrawerVisible}
          expertID={expertID}
          tabStatus={"complete"}
          getExperts={getExperts}
        />
      </>
    </>
  );
}
export default CompletedTable;
