import { Button, Pagination, Tag, Tooltip, Popover, Divider, List } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    setIsOpenCreatProject,
    setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import { convertToTitleCase } from "../../../Utils/Util";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import EmptyStatesData from "../../Components/EmptyStates";
import { BsUnlock } from "react-icons/bs";

function UnlockedCompanyData({
    functions,
    data,
    count,
    isLoading,
    isValidating,
}) {
    const {
        fetchDataLibraryData,
    } = functions;
    const userData = JSON.parse(localStorage.getItem("userData"));

    const { dataLibraryPagination, isLoadingPagination, searchVal } = useSelector(
        (state) => state.dataLibraryReducer
    );
    const dispatch = useDispatch();
    const [showAll, setShowAll] = React.useState(false);
    const toggleShowAll = (id) => {
        setShowAll((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const capitalizeFirstLetter = React.useCallback((string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }, []);

    const dataTrans = React.useMemo(() => EmptyStatesData?.companyTranscript, []);
    const goToDetailsPage = (elem) => {

    }
    if (!dataTrans) return null;

    return (
        <>
            {isLoading || isValidating || isLoadingPagination ? (
                <>
                    <div
                        className="d-flex justify-content-center align-items-center text-center"
                        style={{ minHeight: "50vh" }}
                    >
                        <Loader />
                    </div>
                </>
            ) : data?.length > 0 && !isLoading && !isValidating ? (
                <div className="">
                    <List
                        size="small"
                        header={
                            <Row className="w-100 mt-1">
                                <Col lg={10}>
                                    <Row md={12}>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Company Name</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong className="fw-bolder">Ticker Symbol</strong>
                                        </Col>
                                        <Col md={3}>
                                            <strong className="fw-bolder">Category</strong>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col lg={2}>
                                    <Row md={12}>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Listing Status</strong>
                                        </Col>
                                        <Col md={6}>
                                            <strong className="fw-bolder">Credits</strong>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        }
                        bordered
                        dataSource={data}
                        pagination={false}
                        scroll={{ y: "calc(100vh - 400px)" }}
                        renderItem={(curElem, id) => (
                            <Col xl={12} key={id}>
                                <List.Item className="border-bottom">
                                    <Row className="w-100">
                                        <Col xl={10} lg={9}>
                                            <Row md={12}>
                                                <Col md={6}>

                                                    <Tooltip
                                                        title={capitalizeFirstLetter(curElem?.name)}
                                                    >
                                                        <p
                                                            className="fw-bolder truncate-text mb-0"
                                                            style={{
                                                                fontSize: "12px",
                                                                cursor: "pointer"
                                                            }}

                                                        >
                                                            {" "}
                                                            {capitalizeFirstLetter(curElem?.name)}
                                                        </p>
                                                    </Tooltip>
                                                </Col>
                                                <Col md={3}>

                                                    <small className="fw-normal truncate-text pointer" >
                                                        {curElem?.expertData?.[0]?.current_designation ||
                                                            "NA"}
                                                    </small>
                                                </Col>
                                                <Col md={3}>

                                                    <small className="fw-normal truncate-text pointer" >
                                                        {curElem?.category ||
                                                            "NA"}
                                                    </small>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xl={2} lg={3}>
                                            <Row md={12}>
                                                <Col md={6}>
                                                    <small className="fw-normal">
                                                        {curElem?.listing_status}
                                                    </small>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-start align-items-center"
                                                    md={6}
                                                >
                                                    <>
                                                        <Tag
                                                            bordered={false}
                                                            className="tagContainer view"
                                                            onClick={() => {
                                                                goToDetailsPage(curElem);
                                                            }
                                                            }
                                                        >
                                                            <span
                                                                style={{ display: "flex", alignItems: "center" }}
                                                            >
                                                                <BsUnlock className="me-1 tag_icon" />
                                                                View
                                                            </span>
                                                        </Tag>

                                                    </>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </List.Item>
                            </Col>
                        )}
                        style={{ background: "#ffff" }}
                    />
                    <Pagination
                        className="text-end mt-2"
                        size="small"
                        total={count}
                        current={dataLibraryPagination?.page}
                        pageSize={dataLibraryPagination?.limit}
                        onChange={(page, pageSize) => {
                            fetchDataLibraryData({
                                paginate: true,
                                page: page,
                                limit: pageSize,
                                key: searchVal,
                            });
                        }}
                        showSizeChanger
                    />
                </div>
            ) : (
                <Row>
                    <Col md={12}>
                        <div
                            className="empty-state-container"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <div
                                className="image-container"
                                style={{ flex: 1, textAlign: "center" }}
                            >
                                <img
                                    src={dataTrans?.image}
                                    alt={dataTrans?.feature}
                                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                                />
                            </div>
                            <div
                                className="content-container"
                                style={{ flex: 1, textAlign: "left" }}
                            >
                                <p
                                    className="p-0 mt-0 pt-0 mb-3"
                                    style={{ lineHeight: "28px", fontWeight: "300" }}
                                >
                                    {dataTrans?.content}
                                </p>
                                <button
                                    className="btn viewButton findme-btn"
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                        dispatch(setIsOpenCreatProject(true));
                                        dispatch(setIsOpenProject(false));
                                        // closeProjectModal(false)
                                    }}
                                >
                                    {dataTrans?.cta}
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}

        </>
    );
}

export default UnlockedCompanyData;
