import { combineReducers } from "@reduxjs/toolkit";
import { newsReducer } from "../NewDesign/Pages/Dashboard/news.slice";
import { transcriptReducer } from "../NewDesign/Pages/Dashboard/Transcript.Slice";
import transcriptChatReducer from "../NewDesign/Pages/Transcript/TranscriptChat.slice";
import { userReducer } from "../NewDesign/Pages/Dashboard/user.slice";
import { paymentReducer } from "../NewDesign/Pages/Credit/Payment.Slice";
import { expertReducer } from "../NewDesign/Pages/ExpertPage/Expert.Slice";
import { transcriptExpertReducer } from "../NewDesign/Pages/V2/SearchPage/transcriptExpert.slice";
import { companySearchReducer } from "../NewDesign/Pages/V2/CompanyPage/companySearch.slice";
import { projectReducer } from "../NewDesign/Pages/V2/Dashboard/Project.Slice";
import { SearchPageReducer } from "../NewDesign/Pages/V2/SearchPage/SearchPage.slice";
import { dashboardReducer } from "../NewDesign/Pages/V2/Dashboard/dashboard.slice";
import { companyTabSliceReducer } from "../NewDesign/Pages/V2/CompanyPage/CompanyTab.Slice";
import { complianceSliceReducer } from "../NewDesign/Pages/V2/CompanyPage/Compliance.slice";
import { litigationSliceReducer } from "../NewDesign/Pages/V2/CompanyPage/Liticagtion.slice";
import { userManagementReducer } from "../NewDesign/Pages/User_management/userManagement.slice";
import { dataLibraryReducer } from "../NewDesign/Pages/DataLibrary/DataLibrary.slice";

//there are the reducers we created in whole application
const reducers = combineReducers({
  news: newsReducer,
  transcript: transcriptReducer,
  dataLibraryReducer: dataLibraryReducer,
  transcriptChatReducer: transcriptChatReducer,
  transcriptExpertReducer: transcriptExpertReducer,
  user: userReducer,
  payment: paymentReducer,
  expert: expertReducer,
  companySearch: companySearchReducer,
  project: projectReducer,
  SearchPage: SearchPageReducer,
  dashboardReducer: dashboardReducer,
  companyTabSliceReducer: companyTabSliceReducer,
  complianceSliceReducer: complianceSliceReducer,
  litigationSliceReducer: litigationSliceReducer,
  userManagementReducer: userManagementReducer,
});
export default reducers;
