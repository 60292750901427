import { Card } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";

function PrimaryTranscript({ title, cls }) {
  return (
    <div className={`${cls}`}>
      <Card
        className="mb-2"
        styles={{
          header: {
            border: "none",
            padding: "0px 15px",
          },
          title: {
            padding: "0px",
          },
        }}
        title={<div>{title}</div>}
      >
        <div className="ctm_header mt-2">
          <Row>
            <Col xs={4}>
              <div>Title</div>
            </Col>
            <Col xs={3}>
              <div>Expert</div>
            </Col>
            <Col xs={3}>
              <div>Company</div>
            </Col>
            <Col xs={2}>
              <div>Date</div>
            </Col>
          </Row>
        </div>
        <div className="ctm_body">
          <Row>
            <Col xs={4}>
              <div>Evaluting a joint venture in newyork</div>
            </Col>
            <Col xs={3}>
              <div>After Sales Manager</div>
            </Col>
            <Col xs={3}>
              <div>Cummies Alberia</div>
            </Col>
            <Col xs={2}>
              <div>26 Dec 2024</div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div>Evaluting a joint venture in newyork</div>
            </Col>
            <Col xs={3}>
              <div>After Sales Manager</div>
            </Col>
            <Col xs={3}>
              <div>Cummies Alberia</div>
            </Col>
            <Col xs={2}>
              <div>26 Dec 2024</div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div>Evaluting a joint venture in newyork</div>
            </Col>
            <Col xs={3}>
              <div>After Sales Manager</div>
            </Col>
            <Col xs={3}>
              <div>Cummies Alberia</div>
            </Col>
            <Col xs={2}>
              <div>26 Dec 2024</div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              <div>Evaluting a joint venture in newyork</div>
            </Col>
            <Col xs={3}>
              <div>After Sales Manager</div>
            </Col>
            <Col xs={3}>
              <div>Cummies Alberia</div>
            </Col>
            <Col xs={2}>
              <div>26 Dec 2024</div>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}

export default PrimaryTranscript;
