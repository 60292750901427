import { Avatar, Card } from "antd";
import React from "react";
import { MdPerson } from "react-icons/md";

const ExpertAvatarCard = ({ title }) => {
  return (
    <Card
      className="mb-4"
      styles={{
        header: {
          border: "none",
          padding: "0px 15px",
        },
        title: {
          padding: "0px",
        },
        body: {
          padding: "20px 0",
        },
      }}
      title={<div>{title}</div>}
    >
      <div className="custom_table avatar_container gap-3">
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "50px",
            width: "50px",
          }}
        >
          <MdPerson />
        </Avatar>
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "30px",
            width: "30px",
          }}
        >
          <MdPerson />
        </Avatar>
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "30px",
            width: "30px",
          }}
        >
          <MdPerson />
        </Avatar>
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "30px",
            width: "30px",
          }}
        >
          <MdPerson />
        </Avatar>
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "30px",
            width: "30px",
          }}
        >
          <MdPerson />
        </Avatar>
        <Avatar
          style={{ backgroundColor: "#EFEFEF", color: "#f33636" }}
          size={{
            xs: 24,
            sm: 32,
            md: 40,
            lg: 64,
            xl: 70,
          }}
          sx={{
            bgcolor: "blue",
            height: "30px",
            width: "30px",
          }}
        >
          <MdPerson />
        </Avatar>
      </div>
    </Card>
  );
};

export default ExpertAvatarCard;
