import { Button, Card } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "./Dashboard.css";
import "./AnswerPage.css";
import { GrAttachment } from "react-icons/gr";
import TableCard from "../../../Components/TableCard";
import ExpertAvatarCard from "./ExpertAvatarCard";
import { HiMiniWallet } from "react-icons/hi2";
import PrimaryTranscript from "../../../Components/PrimaryTranscript";

const AnswerPage = () => {
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Dashboard"}</title>
      </Helmet>
      <div className="content-wrapper bg-light" id="main-screen">
        <div className="content-body">
          <Row>
            <Col lg={6} className="answer-card-body">
              <Card
                title={
                  <div className="d-flex align-items-center">
                    <HiMiniWallet className="me-2" />
                    <span>Answer</span>
                  </div>
                }
                styles={{
                  body: {
                    padding: "0px 15px 15px 15px",
                  },
                }}
              >
                <div className="answer_body">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                  <ol type="1">
                    <li>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </li>
                    <li>
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged. It was popularised in the 1960s with the
                      release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old.
                    </li>
                    <li>
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words, consectetur, from a Lorem Ipsum passage, and
                      going through the cites of the word in classical
                      literature, discovered the undoubtable source.
                    </li>
                    <li>
                      Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                      Finibus Bonorum et Malorum" (The Extremes of Good and
                      Evil) by Cicero, written in 45 BC.
                    </li>
                    <li>
                      This book is a treatise on the theory of ethics, very
                      popular during the Renaissance. The first line of Lorem
                      Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                      in section 1.10.32.
                    </li>
                    <li>
                      Lorem Ipsum has been the industry's standard dummy text
                      ever since the 1500s, when an unknown printer took a
                      galley of type and scrambled it to make a type specimen
                      book.
                    </li>
                    <li>
                      It has survived not only five centuries, but also the leap
                      into electronic typesetting, remaining essentially
                      unchanged. It was popularised in the 1960s with the
                      release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </li>
                    <li>
                      Contrary to popular belief, Lorem Ipsum is not simply
                      random text.
                    </li>
                    <li>
                      It has roots in a piece of classical Latin literature from
                      45 BC, making it over 2000 years old.
                    </li>
                    <li>
                      Richard McClintock, a Latin professor at Hampden-Sydney
                      College in Virginia, looked up one of the more obscure
                      Latin words, consectetur, from a Lorem Ipsum passage, and
                      going through the cites of the word in classical
                      literature, discovered the undoubtable source.
                    </li>
                    <li>
                      Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                      Finibus Bonorum et Malorum" (The Extremes of Good and
                      Evil) by Cicero, written in 45 BC.
                    </li>
                    <li>
                      This book is a treatise on the theory of ethics, very
                      popular during the Renaissance. The first line of Lorem
                      Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                      in section 1.10.32.
                    </li>
                  </ol>
                </div>
                <div className="">
                  <div className="follow_up_container">
                    <GrAttachment /> Ask a follow-up
                  </div>
                </div>
              </Card>
            </Col>
            <Col lg={6}>
              <Row className="g-2">
                <Col>
                  <Button className="button-gradient">
                    Request a Custom Transcript
                  </Button>
                </Col>
                <Col>
                  <Button className="button-gradient">
                    Request an Expert Call
                  </Button>
                </Col>
              </Row>
              <div className="answer_body_wrapper">
                <div className="answer_body_sec">
                  <PrimaryTranscript
                    cls="answer_table"
                    title={
                      <>
                        <span>Primary Transcript</span>
                        <small className="text-muted ms-2">
                          (12 Primary transcripts found)
                        </small>
                      </>
                    }
                    type={"transcript"}
                  />
                  <PrimaryTranscript
                    cls="answer_table"
                    title={
                      <>
                        <span>Secondary Research Reports</span>
                        <small className="text-muted ms-2">
                          (28 Secondary research reports found)
                        </small>
                      </>
                    }
                    type={"expert"}
                  />
                  <PrimaryTranscript
                    cls="answer_table"
                    title={"Latest News"}
                    type={"news"}
                  />
                  <ExpertAvatarCard title={"Expert Profiles"} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AnswerPage;
