import React, { memo, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tooltip, Input, List, Pagination, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useExpert from "../../Hooks/useExpert";
import { useSelector, useDispatch } from "react-redux";
import { CreateProjectModal } from "../../../components/CreateProjectModal";
import useProject from "../../Hooks/useProject";
import useDashboard from "../../Hooks/useDashboard";
import { setSelectedExpert } from "./Expert.Slice";
import ExpertSidebar from "./ExpertSidebar";
import EmptyStatesData from "../../Components/EmptyStates";
import company_icon3 from "../../../assests/images/company_notfound.png";

import {
  setIsOpenCreatProject,
  setIsOpenProject,
} from "../V2/Dashboard/Project.Slice";
import UnlockExpertButton from "../../Components/UnlockExpertButton";
import { setToggle } from "../V2/Dashboard/dashboard.slice";
import ConditionalRender from "../../../Utils/ConditionalRender";
import { ProjectListModal } from "../../../components/ProjectListModal";
import { checkForValidURL, truncatelast } from "../../../Utils/Util";
import Loader from "../../Components/Loader";
import SelectionOption from "../../Pages/ExpertPage/SelectionOption";

const ExpertPage = () => {
  const [hoveredItemId, setHoveredItemId] = React.useState(null);
  const [advanceSearchOpen, setAdvanceSearchOpen] = React.useState(false);
  const { projectListById } = useSelector((state) => state.project);
  const { AddExpertProject, getProjectListById, mutateProjectList, isLoading } =
    useProject();
  const { getExpertById } = useDashboard();
  const { selectedExpert } = useSelector((state) => state.expert);
  const divRef = React.useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const {
    expertLabList,
    getStore,
    loadingExperts: isLoadingExperts,
    stateList,
    stateFunctionList,
    stateFunctionListFilter,
    stateListFilter,
    handleSearchExperts,
    callOnceFilter,
    getExpertsOptions,
    filterRef,
    callFilter,
    handlePageChange,
    onlyFirstTimeAndSearch,
  } = useExpert();
  const dispatch = useDispatch();

  // Ensure the useEffect is not conditional
  const updateWidth = () => {
    if (divRef?.current) {
      setTimeout(() => {
        setWidth(divRef?.current?.offsetWidth);
      }, 10);
    }
  };
  useEffect(() => {
    // Update width on mount
    updateWidth();

    // Add event listener to update width on window resize
    window.addEventListener("resize", updateWidth);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []); // Ensure the useEffect is not conditional

  const dataExpert = React.useMemo(() => EmptyStatesData?.companyExpert, []);
  if (!dataExpert) return null;

  const ListItem = ({ data }) => {
    return (
      <List.Item>
        <Row className="w-100" key={data?._id}>
          <Col md={4}>
            <Row md={12}>
              <Col className="pointer" md={12} onClick={() => {
                dispatch(setToggle(true));
                dispatch(setSelectedExpert(data));
                getExpertById(data?._id);
                updateWidth();
              }}>
                <Tooltip title={data?.currunt_designation}>
                  <strong className="fw-bolder truncate-text fs-12">
                    {data?.currunt_designation || "-"}
                  </strong>
                </Tooltip>
              </Col>
            </Row>
          </Col>
          <Col md={8}>
            <Row md={12}>
              <Col className="pointer" md={4} onClick={() => {
                dispatch(setToggle(true));
                dispatch(setSelectedExpert(data));
                getExpertById(data?._id);
                updateWidth();
              }}>
                <span className="d-flex align-items-center gap-2">
                  <span>
                    <img
                      src={checkForValidURL(data?.current_company_logo)}
                      alt="company logo"
                      height={"25px"}
                      width={"25px"}
                    />
                  </span>
                  <small className="fw-normal truncate-text">
                    <Tooltip title={data?.currunt_company}>
                      {truncatelast(data?.currunt_company) || "-"}
                    </Tooltip>
                  </small>
                </span>
              </Col>
              <Col className="pointer" md={4} onClick={() => {
                dispatch(setToggle(true));
                dispatch(setSelectedExpert(data));
                getExpertById(data?._id);
                updateWidth();
              }}>
                <Tooltip title={data?.previous_company?.split("+")[1]}>
                  <span className="d-flex align-items-center gap-2">
                    {(data?.previous_company?.split("+")[1] ||
                      data?.previous_company?.split("+")[0]) && (
                        <span>
                          <img
                            src={checkForValidURL(data?.previous_company_logo)}
                            alt="company logo"
                            height={"25px"}
                            width={"25px"}
                          />
                        </span>
                      )}
                    <Tooltip
                      title={
                        data?.previous_company?.split("+")[1]
                          ? truncatelast(data?.previous_company?.split("+")[1])
                          : truncatelast(
                            data?.previous_company?.split("+")[0]
                          ) || "-"
                      }
                    >
                      <small className="fw-normal truncate-text">
                        {data?.previous_company?.split("+")[1]
                          ? truncatelast(data?.previous_company?.split("+")[1])
                          : truncatelast(
                            data?.previous_company?.split("+")[0]
                          ) || "-"}
                      </small>
                    </Tooltip>
                  </span>
                </Tooltip>
              </Col>
              <Col className="pointer" md={width < 1100 ? 2 : 3} onClick={() => {
                dispatch(setToggle(true));
                dispatch(setSelectedExpert(data));
                getExpertById(data?._id);
                updateWidth();
              }}>
                <Tooltip title={data?.country}>
                  <span className="d-flex align-items-center gap-2">
                    <small className="fw-normal truncate-text">
                      {data?.country || "-"}
                    </small>
                  </span>
                </Tooltip>
              </Col>
              <Col
                className="pointer"
                md={width < 1100 ? 2 : 1}
                onMouseEnter={() => setHoveredItemId(data?._id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                <UnlockExpertButton
                  data={data}
                  hoveredItemId={hoveredItemId}
                  unlockFunction={() => {
                    dispatch(setToggle(true));
                    dispatch(setSelectedExpert(data));
                    getExpertById(data?._id);
                    updateWidth();
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
    );
  };
  const ListItemMemo = memo(ListItem);

  return (
    <>
      <div>
        <Row className="mt-2 mb-2">
          <Col className="w-100 d-flex justify-content-between align-items-center">
            <h5 style={{ fontWeight: 600, margin: 0 }}>
              <span className="me-2">Expert Library</span>
              <Tag color="blue">{expertLabList?.count}</Tag>
            </h5>
            <button
              className="btn viewButton"
              onClick={() => setAdvanceSearchOpen(!advanceSearchOpen)}
            >
              Advance Search
            </button>
          </Col>
        </Row>
        <div className="position-relative">
          <Row>
            <Col>
              <Input
                placeholder="Search expert library"
                value={stateList.searchRgx}
                onChange={(e) => {
                  dispatch(setSelectedExpert({}));
                  dispatch(setToggle(false));
                  onlyFirstTimeAndSearch.current = false;
                  stateFunctionList.setSearchRgx(e.target.value);
                }}
                prefix={<SearchOutlined />}
                className="mb-2 fs-16-input"
                size="large"
              />
            </Col>
          </Row>
          <SelectionOption
            stateList={stateList}
            stateFunctionList={stateFunctionList}
            handleSearchExperts={(clearAll) => {
              handleSearchExperts(clearAll);
              setAdvanceSearchOpen(false);
            }}
            stateListFilter={stateListFilter}
            stateFunctionListFilter={stateFunctionListFilter}
            callOnce={callOnceFilter}
            callFilter={callFilter}
            getExpertsOptions={getExpertsOptions}
            filterRef={filterRef}
            advanceSearchOption={advanceSearchOpen}
            setAdvanceSearchOpen={setAdvanceSearchOpen}
          />
        </div>
        <Row className="g-3">
          <ConditionalRender condition={isLoadingExperts}>
            <div
              className="d-flex justify-content-center align-items-center text-center"
              style={{ minHeight: "80vh" }}
            >
              <Loader />
            </div>
          </ConditionalRender>
          <ConditionalRender
            condition={expertLabList?.data?.length === 0 && !isLoadingExperts}
          >
            <>
              <Col md={12}>
                <div
                  className="empty-state-container"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <div
                    className="image-container"
                    style={{ flex: 1, textAlign: "center" }}
                  >
                    <img
                      src={dataExpert?.image}
                      alt={dataExpert?.feature}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                  <div
                    className="content-container"
                    style={{ flex: 1, textAlign: "left" }}
                  >
                    <p
                      className="p-0 mt-0 pt-0 mb-3"
                      style={{ lineHeight: "28px", fontWeight: "300" }}
                    >
                      {dataExpert?.content}
                    </p>
                    <button
                      className="btn viewButton findme-btn"
                      style={{ fontSize: "12px" }}
                      onClick={() => {
                        dispatch(setIsOpenCreatProject(true));
                        dispatch(setIsOpenProject(false));
                      }}
                    >
                      {dataExpert?.cta}
                    </button>
                  </div>
                </div>
              </Col>
            </>
          </ConditionalRender>
          <ConditionalRender
            condition={expertLabList?.data?.length > 0 && !isLoadingExperts}
          >
            <>
              <Col sm={12} lg={selectedExpert?._id ? 8 : 16}>
                <div ref={divRef} id="expertLibrary" className="height100-196">
                  <List
                    size="small"
                    header={
                      <div>
                        <Row className="w-100 my-2">
                          <Col md={4}>
                            <strong className="fw-bolder">Designation</strong>
                          </Col>
                          <Col md={8}>
                            <Row>
                              <Col md={4}>
                                <strong className="fw-bolder">Company</strong>
                              </Col>
                              <Col md={4}>
                                <strong className="fw-bolder">
                                  Previous Company
                                </strong>
                              </Col>
                              <Col md={width < 1100 ? 2 : 3}>
                                <strong className="fw-bolder">Country</strong>
                              </Col>
                              <Col md={width < 1100 ? 2 : 1}>
                                <strong className="fw-bolder">Credits</strong>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    }
                    bordered
                    pagination={false}
                    dataSource={expertLabList?.data}
                    renderItem={(item) => <ListItemMemo data={item} />}
                    style={{ background: "#ffff" }}
                  />
                  {expertLabList?.count > 10 && (
                    <Pagination
                      className="text-end mt-2"
                      size="small"
                      total={expertLabList?.count}
                      current={stateList.currentPage}
                      pageSize={stateList?.pageSize}
                      onChange={(page, pageSize) => {
                        handlePageChange(page, pageSize);
                      }}
                      showSizeChanger={true}
                      disabled={false}
                    />
                  )}
                </div>
              </Col>
              <Col
                sm={12}
                lg={4}
                className={`${selectedExpert?._id ? "" : "d-none"}`}
              >
                <ExpertSidebar
                  updateFunction={updateWidth}
                  getStore={getStore}
                />
              </Col>
            </>
          </ConditionalRender>
        </Row>
      </div>
      <ProjectListModal
        selectedExpertId={selectedExpert?._id}
        country={selectedExpert?.country}
        isLoading={isLoading}
        functions={{
          projectListById,
          AddExpertProject,
          getProjectListById,
          mutateProjectList,
        }}
      />

      <CreateProjectModal
        buttonText="Custom Expert Search"
        mutateProjectList={mutateProjectList}
      />
    </>
  );
};

export default ExpertPage;
