import { Tooltip } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import nextyn_logo_25c from "../../assests/images/Nextyn_logo.png";
import Header from "./Header";
import { Modal, Button } from "antd";

import { CiGrid41 } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { CiPlay1 } from "react-icons/ci";
import { CiMedal } from "react-icons/ci";
import { CiReceipt } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { CiSettings } from "react-icons/ci";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { RiLogoutCircleLine } from "react-icons/ri";
import ConditionalRender from "../../Utils/ConditionalRender";

function Sidebar({ skipHeader = false, cls = false }) {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const permission = React.useMemo(
    () => userData?.user?.role_id,
    [userData?.user?.role_id]
  );
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isUserManagementOpen, setUserManagementOpen] = useState(false);
  const handleCancel = React.useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const toggleUserManagement = () =>
    setUserManagementOpen(!isUserManagementOpen);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function toggleLeftBar() {
    if (isUserManagementOpen) {
      toggleUserManagement();
    }
    setSidebarOpen(!isSidebarOpen);
    const toggle = document.getElementById("header-toggle"),
      nav = document.getElementById("nav-bar"),
      bodypd = document.getElementById("body-pd");
    // headerpd = document.getElementById("header");
    // mainScreen = document.getElementById("main-screen");

    nav?.classList?.toggle("show");
    // change icon
    toggle?.classList?.toggle("bx-x");
    // add padding to body
    bodypd?.classList?.toggle("active");
    // mainScreen?.classList?.toggle("bodyPd");
    // add padding to header
    // headerpd?.classList?.toggle("body-pd");
    isSidebarOpen && isUserManagementOpen && setUserManagementOpen(false);
  }

  document.addEventListener("DOMContentLoaded", function (event) {
    /*===== LINK ACTIVE =====*/
    const linkColor = document.querySelectorAll(".nav_link");

    function colorLink() {
      if (linkColor) {
        linkColor.forEach((l) => l.classList.remove("active"));
        this.classList.add("active");
      }
    }
    linkColor.forEach((l) => l.addEventListener("click", colorLink));

    // Your code to run since DOM is loaded and ready
  });

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("isAuth");
    navigate("/");
  };

  return (
    <>
      <ConditionalRender condition={!skipHeader}>
        <Header cls={cls} />
      </ConditionalRender>
      <div className="l-navbar" id="nav-bar">
        <nav className="sidebar_nav">
          <div>
            {" "}
            <NavLink to="/dashboard" className="nav_logo">
              {" "}
              <div className="manage_logo" style={{ left: "-4px" }}>
                <img
                  className="nav_logo-icon"
                  src={nextyn_logo_25c}
                  alt="Nextyn Logo"
                />
              </div>
            </NavLink>
            <div className="toggle_icon">
              <LeftCircleOutlined
                className="left_icon"
                onClick={toggleLeftBar}
              />
              {/* <ConditionalRender condition={isSidebarOpen}>
                <LeftCircleOutlined
                  className="left_icon"
                  onClick={toggleLeftBar}
                />
              </ConditionalRender>
              <ConditionalRender condition={!isSidebarOpen}>
                <RightCircleOutlined
                  className="right_icon"
                  onClick={toggleLeftBar}
                />
              </ConditionalRender> */}
            </div>
            <div className="nav_list">
              <NavLink
                to="/dashboard"
                className="nav_link"
                activeClassName="active"
              >
                <ConditionalRender condition={isSidebarOpen}>
                  <>
                    <CiGrid41 size={20} />
                    <span className="nav_name">Dashboard</span>
                  </>
                </ConditionalRender>

                <ConditionalRender condition={!isSidebarOpen}>
                  <>
                    <Tooltip placement="right" title={"Dashboard"}>
                      <CiGrid41 size={20} />
                    </Tooltip>
                    <span className="nav_name">Dashboard</span>
                  </>
                </ConditionalRender>
              </NavLink>

              <ConditionalRender
                condition={
                  (permission &&
                    !Object?.values(permission?.projects).every(
                      (value) => value === false
                    )) ||
                  (permission && permission?.projects?.view)
                }
              >
                <NavLink
                  to="/projects"
                  className="nav_link"
                  activeClassName="active"
                >
                  <ConditionalRender condition={isSidebarOpen}>
                    <>
                      <CiViewList size={20} />
                      <span className="nav_name">Projects</span>
                    </>
                  </ConditionalRender>
                  <ConditionalRender condition={!isSidebarOpen}>
                    <>
                      <Tooltip placement="right" title={"Projects"}>
                        <CiViewList size={20} />
                      </Tooltip>
                      <span className="nav_name">Projects</span>
                    </>
                  </ConditionalRender>
                </NavLink>
              </ConditionalRender>

              <ConditionalRender condition={permission?.callRecording?.view}>
                <NavLink
                  to="/call-recordings"
                  className="nav_link"
                  activeClassName="active"
                >
                  <ConditionalRender condition={isSidebarOpen}>
                    <>
                      <CiPlay1 size={20} />
                      <span className="nav_name">Call Recordings</span>
                    </>
                  </ConditionalRender>
                  <ConditionalRender condition={!isSidebarOpen}>
                    <>
                      <Tooltip placement="right" title={"Call Recordings"}>
                        <CiPlay1 size={20} />
                      </Tooltip>
                      <span className="nav_name">Call Recordings</span>
                    </>
                  </ConditionalRender>
                </NavLink>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  (permission &&
                    !Object.values(permission?.experts).every(
                      (value) => value === false
                    )) ||
                  (permission && permission?.experts?.view)
                }
              >
                <NavLink
                  to="/find-expert"
                  className="nav_link"
                  activeClassName="active"
                >
                  <ConditionalRender condition={isSidebarOpen}>
                    <>
                      <CiMedal size={20} />
                      <span className="nav_name">Experts</span>
                    </>
                  </ConditionalRender>
                  <ConditionalRender condition={!isSidebarOpen}>
                    <>
                      <Tooltip placement="right" title="Experts">
                        <CiMedal size={20} />
                      </Tooltip>
                      <span className="nav_name">Experts</span>
                    </>
                  </ConditionalRender>
                </NavLink>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  (permission &&
                    !Object.values(permission?.transcript).every(
                      (value) => value === false
                    )) ||
                  (permission && permission?.transcript?.view)
                }
              >
                <NavLink
                  to="/transcript"
                  className="nav_link"
                  activeClassName="active"
                >
                  <ConditionalRender condition={isSidebarOpen}>
                    <>
                      <CiReceipt size={20} />
                      <span className="nav_name">Transcripts</span>
                    </>
                  </ConditionalRender>
                  <ConditionalRender condition={!isSidebarOpen}>
                    <>
                      <Tooltip placement="right" title="Transcripts">
                        <CiReceipt size={20} />
                      </Tooltip>
                      <span className="nav_name">Transcripts</span>
                    </>
                  </ConditionalRender>
                </NavLink>
              </ConditionalRender>
              <NavLink
                to={"/data-library"}
                className="nav_link"
                activeClassName="active"
              >
                <ConditionalRender condition={isSidebarOpen}>
                  <>
                    <CiStar size={20} />
                    <span className="nav_name">Data Library</span>
                  </>
                </ConditionalRender>
                <ConditionalRender condition={!isSidebarOpen}>
                  <>
                    <Tooltip placement="right" title="Data Library">
                      <CiStar size={20} />
                    </Tooltip>
                    <span className="nav_name">Data Library</span>{" "}
                  </>
                </ConditionalRender>
              </NavLink>
              <NavLink
                to={"/watchlist"}
                className="nav_link"
                activeClassName="active"
              >
                <ConditionalRender condition={isSidebarOpen}>
                  <>
                    <CiStar size={20} />
                    <span className="nav_name">Watchlist</span>
                  </>
                </ConditionalRender>
                <ConditionalRender condition={!isSidebarOpen}>
                  <>
                    <Tooltip placement="right" title="Watchlist">
                      <CiStar size={20} />
                    </Tooltip>
                    <span className="nav_name">Watchlist</span>{" "}
                  </>
                </ConditionalRender>
              </NavLink>

              <ConditionalRender
                condition={
                  permission &&
                  !Object.values(permission?.userManagement).every(
                    (value) => value === false
                  ) &&
                  isSidebarOpen
                }
              >
                <div
                  className="nav_link submenu_link"
                  onClick={() => {
                    toggleUserManagement();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <CiSettings size={20} />
                  <span className="nav_name">User Management</span>
                </div>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  permission &&
                  !Object?.values(permission?.userManagement)?.every(
                    (value) => value === false
                  ) &&
                  !isSidebarOpen
                }
              >
                <div
                  className="nav_link submenu_link"
                  onClick={() => {
                    toggleUserManagement();
                    toggleLeftBar();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Tooltip placement="right" title="User Management">
                    <CiSettings size={20} />
                  </Tooltip>
                  <span className="nav_name">User Management</span>
                </div>
              </ConditionalRender>

              <ConditionalRender
                condition={
                  isUserManagementOpen &&
                  permission &&
                  !Object.values(permission?.userManagement).every(
                    (value) => value === false
                  )
                }
              >
                <div className="nav_sub d-flex justify-content-center align-items-center">
                  <div>
                    <NavLink
                      to="/usermangement/roles"
                      className="nav_link submenu_link"
                    >
                      <span className="nav_name">Roles</span>
                    </NavLink>
                    <NavLink
                      to="/usermangement/users"
                      className="nav_link submenu_link"
                    >
                      <span className="nav_name">Users</span>
                    </NavLink>
                  </div>
                </div>
              </ConditionalRender>
            </div>
          </div>
          <div
            className="nav_link"
            onClick={() => setIsModalOpen(true)}
            style={{ cursor: "pointer" }}
          >
            <RiLogoutCircleLine style={{ fontSize: "18px" }} />
            <span className="nav_name">Log Out</span>{" "}
          </div>
        </nav>
      </div>

      <Modal
        centered
        title="Log Out"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{
              backgroundColor: "rgb(76, 187, 23)",
              borderColor: "rgb(76, 187, 23)",
              color: "#fff",
            }}
            onClick={async () => {
              handleLogout();
            }}
          >
            Log Out
          </Button>,
        ]}
      >
        <p>Are you sure you want to logout from the current session</p>
      </Modal>
    </>
  );
}
export default Sidebar;
