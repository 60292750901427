import { createSlice } from "@reduxjs/toolkit";

//setup initial state
const initialState = {
    data: [],
    unlockedData: { data: [], count: 0 },
    lockedData: { data: [], count: 0 },
    isLoadingPagination: false,
    count: 0,
    status: 'idle',
    error: null,
    searchVal: "",
    dataLibraryPagination: { page: 1, limit: 20 }
};


const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setDataLibraryData: (state, { payload }) => {
            state.data = payload
        },
        setIsLoadingPagination: (state, { payload }) => {
            state.isLoadingPagination = payload
        },
        setUnlockedData: (state, { payload }) => {
            state.unlockedData = payload
        },
        setLockedData: (state, { payload }) => {
            state.lockedData = payload
        },
        setCount: (state, { payload }) => {
            state.count = payload
        },
        setSearchVal: (state, { payload }) => {
            state.searchVal = payload
        },
        setDataLibraryPagination: (state, { payload }) => {
            state.dataLibraryPagination = payload
        },
    },
});


const dataLibraryReducer = dataSlice.reducer
export const { setDataLibraryData, setCount, setDataLibraryPagination, setUnlockedData,
    setLockedData, setIsLoadingPagination, setSearchVal } = dataSlice.actions
export {
    dataLibraryReducer
}
