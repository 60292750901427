import React from 'react'
import { Helmet } from 'react-helmet';
import DataLibraryTable from './DataLibraryTable';

const DataLibraryPage = () => {
  return (
    <>
      <Helmet>
        <title>{"Nextyn IQ - Data Library"}</title>
      </Helmet>
      <div className="content-wrapper bg-light" id="main-screen">
        {/* <Transcript /> */}
        <DataLibraryTable />
      </div>
    </>
  );
}

export default DataLibraryPage
