import { Input, Tabs } from "antd";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useUser from "../../Hooks/useUser";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import { BiLibrary } from "react-icons/bi";
import { LuUnlock } from "react-icons/lu";

import LockedCompanyData from "./LockedCompanyData";
import UnlockedCompanyData from "./UnlockedCompanyData";
import useDataLibrary from "./useDataLibrary";
import { setDataLibraryData, setDataLibraryPagination, setSearchVal } from "./DataLibrary.slice";

function DataLibraryTable() {
    const [unlock, setUnlock] = React.useState(false);
    const { isLoading, isValidating, fetchDataLibraryData } =
        useDataLibrary(unlock);
    const history = useNavigate();
    const { spendCredits } = useUser();

    const { data, unlockedData, lockedData, searchVal } = useSelector(
        (state) => state.dataLibraryReducer
    );
    const creditsData = useSelector((state) => state.user.all_credits);

    function replaceTxt(txt, para) {
        txt = txt?.toUpperCase();
        for (var i = 0; i < para?.length; i++) {
            txt = txt?.replace(para[i], "");
        }
        return txt;
    }

    const goToDetailsPage = (curElem) => {
        history({
            pathname: `/transcript-details/${curElem?._id}`,
            state: { curElem },
        });
    };

    const handleSpendCredit = (transcript_id) => {
        spendCredits({
            transcript_id: transcript_id,
            credits_spend: creditsData?.credits_for_transcript,
        });
    };
    const dispatch = useDispatch();
    const functions = {
        goToDetailsPage,
        handleSpendCredit,
        replaceTxt,
        spendCredits,
        fetchDataLibraryData,
    };
    const items = [
        {
            key: "1",
            label: (
                <span className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        {" "}
                        <BiLibrary style={{ fontSize: "20px" }} />
                    </span>
                    <span>Data Library</span>
                </span>
            ),
            children: (
                <LockedCompanyData
                    functions={functions}
                    data={lockedData?.data}
                    count={lockedData?.count}
                    isLoading={isLoading}
                    isValidating={isValidating}
                />
            ),
        },
        {
            key: "2",
            label: (
                <span className="d-flex justify-content-center align-items-center gap-1">
                    <span>
                        {" "}
                        <LuUnlock style={{ fontSize: "17px" }} />
                    </span>
                    <span>Unlocked Companies</span>
                </span>
            ),
            children: (
                <UnlockedCompanyData
                    functions={functions}
                    data={unlockedData?.data}
                    count={unlockedData?.count}
                    isLoading={isLoading}
                    isValidating={isValidating}
                />
            ),
        },
    ];

    const onChange = React.useCallback(
        (key) => {
            dispatch(setDataLibraryData([]));
            if (key === "2") {
                dispatch(setDataLibraryPagination({ page: 1, limit: 20 }));
                dispatch(setSearchVal(""));
                setUnlock(true);
            }
            if (key === "1") {
                dispatch(setDataLibraryPagination({ page: 1, limit: 20 }));
                dispatch(setSearchVal(""));
                setUnlock(false);
            }
        },
        [dispatch]
    );

    React.useEffect(() => {
        const handler = setTimeout(async () => {
            fetchDataLibraryData({ search: true, key: searchVal?.trim() });
        }, 1000);
        return () => {
            clearTimeout(handler);
        };
    }, [fetchDataLibraryData, searchVal]);
    return (
        <>
            <Row className="mt-2 mb-2">
                <Col>
                    <h5 style={{ fontWeight: 600 }}>
                        <span>Data Library</span>
                    </h5>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input
                        placeholder="Search transcripts library"
                        value={searchVal}
                        onChange={(e) => {
                            let searchValue = e?.target?.value
                            dispatch(setSearchVal(searchValue));
                            // handleSearchInputChange(e);
                        }}
                        prefix={<SearchOutlined />}
                        style={{ marginBottom: 16 }}
                        size="large"
                    />
                </Col>
            </Row>
            {/* <div className={"d-block"}>
        <SelectionOptionTranscript
        // stateList={{
        //   current_company,
        //   previous_company,
        //   email,
        //   industry,
        //   geography,
        //   designation,
        //   experties,
        //   searchRgx,
        //   currentPage,
        // }}
        // stateFunctionList={{
        //   setCompany,
        //   setPreviousCompany,
        //   setEmail,
        //   setIndustry,
        //   setGeography,
        //   setDesignation,
        //   setExperties,
        //   setCurrentPage,
        //   setWordEntered,
        //   setSearchRgx,
        // }}
        // handleSearchExperts={handleSearchExperts}
        // stateListFilter={stateListFilter}
        // stateFunctionListFilter={stateFunctionListFilter}
        // callOnce={callOnce}
        // callFilter={callFilter}
        // getExpertsOptions={getExpertsOptions}
        // filterRef={filterRef}
        />
      </div> */}

            <Row className="heightTranscript">
                <Col>
                    <Tabs items={items} onChange={onChange} />
                </Col>
            </Row>
        </>
    );
}

export default DataLibraryTable;
